/** @jsx jsx */
import { jsx, Flex, Styled,Grid} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";


export const DogAccessories = ({step, order, handleChange, values}) => {
  

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])

  
  

    return (
      <TransitionGroup component={null}>
        {show && (
          <CSSTransition 
            classNames="slide" 
            timeout={3000}>
        <div>
        <Flex sx={{width: "100%", justifyContent: "center"}}>
          <Styled.h2>Where do you currently buy your dog accessories (collars, leash, sweashirt, etc.)</Styled.h2>
        </Flex>

        <Grid columns={[2,2,4]} gap={5} sx={{width: "100%", mt: 5, justifyContent: "center"}}>
          <input
            sx={{display: "none"}}
            className="checkbox"
            id="online"
            name="dog_accessories_store"
            type="radio"
            value="online"
            checked={values.dog_accessories_store === "online" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="online" sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
              <span>Online Store</span>
            </Flex>
          </label>
    
          <input
            sx={{display: "none"}}
            className="checkbox"
            id="retail"
            name="dog_accessories_store"
            type="radio"
            value="retail"
            checked={values.dog_accessories_store === "retail" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="retail" sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
   
              <span>Retail Chain</span>
            </Flex>
          </label>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="boutique"
            name="dog_accessories_store"
            type="radio"
            value="boutique"
            checked={values.dog_accessories_store === "boutique" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="boutique" sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
  
              <span>Pet Boutique</span>
            </Flex>
          </label>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="other"
            name="dog_accessories_store"
            type="radio"
            value="other"
            checked={values.dog_accessories_store === "other" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="other"sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
      
              <span>Other</span>
            </Flex>
          </label>
      
        </Grid>
        </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    ) 
      
}