/** @jsx jsx */
import { jsx, Input, Label} from "theme-ui"


export const DogNameInput = ({dogState, handleDogChange}) => {

  return (    
    dogState.map((val, idx) => {
      const dogId = `name-${idx}`;
      return (
        <div key={`dog-${idx}`} sx={{width: ["100%"], mt: 5}}>
          <Label htmlFor={dogId}>{`Dog #${idx + 1}`}</Label>
          <Input
            type="text"
            name="name"
            data-idx={idx}
            id={dogId}
            className="name" 
            value={dogState[idx].name}
            onChange={handleDogChange}
            placeholder="Name"
          />
        </div>
        
      );      
    })
  )
}
      