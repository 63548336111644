/** @jsx jsx */
import { jsx, Input, Styled, Flex, Textarea} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";


export const DogIssues = ({step, order, dogState, handleDogChange, idx}) => {

  const [show, setShow] = useState(false)
  const [breed, setBreed] = useState([])

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])




  useEffect(() => {

    const q = dogState[idx].breed === "" ? "a" : dogState[idx].breed
    fetch(`https://api.thedogapi.com/v1/breeds/search?q=${q}`,{
      method: "GET",
      headers: {
        'x-api-key': 'c3a871b2-58da-4517-9938-5cebdd29094e'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.length !== 0) {
          setBreed([ ...data ])}
        }
      )

  }, [dogState[idx].breed])

  return (    
    <TransitionGroup component={null}>
    {show && (
      <CSSTransition 
        classNames="slide" 
        timeout={3000}>
          <div key={`dog-${idx}`}>
            <Styled.h2 sx={{textAlign: "center"}}>Does {dogState[idx].name} have  medical issues?</Styled.h2>
           
            <Flex sx={{justifyContent: "center", alignItems: "center"}}>
              <Textarea
                type="text"
                name="health_issues"
                data-idx={idx}
                id={`issues-${idx}`}
                value={dogState[idx].health_issues}
                onChange={handleDogChange}
                placeholder="Medical Issues"
                rows={5}
              /> 

            </Flex>

          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  ) 
}
      