/** @jsx jsx */
import { jsx, Flex, Styled, Button} from "theme-ui"
import {Link, navigate} from "gatsby"
import React, {useState, useEffect} from "react"
import { DogNames, DogFood, DogAccessories, DogInfoFields, DogQuantity, Name, ZipCode, DogIssues } from "."


export const QuizContainer = () => {

  const [values, setValues] = useState({
    name: "",
    email: "",
    zip_code: "",
    dog_quantity: "",
    dog_food_store: "",
    dog_accessories_store: "",
  })

  const blankDog = {
    name: "",
    age: "",
    weight: "",
    sex: "",
    breed: "",
    health_issues: "",
  };

  const [dogState, setDogState] = useState([]);
  
  const addDog = (q) => {
    let dogs = []
    for(var i=0; i < q; i++) {
      dogs.push({...blankDog})
    }
    setDogState([...dogState, ...dogs]);
  };
  

  useEffect(() => {
    addDog(values.dog_quantity)
  },[values.dog_quantity])

  console.log(values.dog_quantity)
  console.log(dogState)



  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }



  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "dog-quiz", ...values, ...dogState})
    })
      .then(() => navigate("/thank-you"))
      .catch(error => alert(error));

    e.preventDefault();
  };


  console.log(encode({ "form-name": "dog-quiz", ...values, ...dogState}))
  
  const stepCount = 6 + (dogState.length * 2)
  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    const{name, value} = event.target
    setValues({...values, [name]: value})
  }


  const handleDogChange = (e) => {
    const updatedDogs = [...dogState];
    updatedDogs[e.target.dataset.idx][e.target.name] = e.target.value;
    setDogState(updatedDogs);
  }

  const next = () => {
    let currentStep = step
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 9 ? 10 : currentStep + 1
    setStep(currentStep)
  }
    
  const prev = () => {
    let currentStep = step
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    setStep(currentStep)
  }


  // The "next" and "previous" button functions
const previousButton =() =>{
  let currentStep = step;
  // If the current step is not 1, then render the "previous" button
  if(currentStep !== 1){
    return (
      <Button
        sx={{px: 5, color: "primary", backgroundColor: "white", minWidth: "150px" }}
        type="button" onClick={prev}>
        Previous
      </Button>
    )
  }
  // ...else return nothing
  return null
}

const nextButton = () => {
  let currentStep = step;
  // If the current step is not 3, then render the "next" button
  if(currentStep < stepCount && currentStep > 0){
    return (
      <Flex sx={{justifyContent: "flex-end", width: "100%"}}>
        <Button
          sx={{px: 5}} 
          type="button" onClick={next}>
          Next
        </Button>        
      </Flex>
    )
  } else if(currentStep === stepCount) {
    return (

      <Flex sx={{justifyContent: "flex-end", width: "100%"}}>
        <Button
          sx={{px: 5}}
          onClick={handleSubmit}
          type="button">
          Submit
        </Button>  
      </Flex>

    )
  }
  // ...else render nothing
  return null;
}

console.log(values)

  return (
    <div sx={{width: "100%",backgroundImage: "linear-gradient(315deg, #045de9 0%, #09c6f9 35%)"}}>
      <div sx={{width: "100%"}}>
        <Flex sx={{justifyContent: "center", alignItems: "center", px: 5, py: 4}}>
          <Styled.a as={Link} to="/" sx={{px: 4,mb: "0px", pb: "0px", fontSize: "24px", color: 'black'}}>
            barkpedia
          </Styled.a>
        </Flex>
        <Flex sx={{width: "100%", alignItems: "center", justifyContent: "center"}} >
          <progress id="file" max="100" sx={{width: "100%"}} value={step/stepCount * 100}> {step/stepCount * 100} </progress>
        </Flex>
      </div>
      <Flex sx={{flexWrap: "wrap", width: "100%"}} style={{minHeight: 'calc(100vh - 165px)'}}>
        <div sx={{width: "100%", px: [2,2,5]}}>
          <Flex sx={{justifyContent: "center", alignItems: "center", height: "100%"}}>
            <form name="dog-quiz" method="POST" data-netlify="true" data-netlify-honeypot="bot-field"  sx={{justifyContent: "center", alignItems: "center", width: ["100%","100%","50%"], px: 5}}>
              <input type="hidden" name="form-name" value="dog-quiz" />
              <Name handleChange={handleChange} values={values} order={1} step={step} />
              <DogQuantity handleChange={handleChange} values={values} order={2} step={step} /> 
              <DogNames handleChange={handleChange} handleDogChange={handleDogChange} values={values} dogState={dogState} order={3} step={step} /> 
              <ZipCode handleChange={handleChange} values={values} order={4} step={step} /> 
              {dogState.map((dogs, i) => (
                <DogInfoFields key={parseInt("34569") + i} handleChange={handleChange} handleDogChange={handleDogChange} values={values} dogState={dogState} order={5+i} step={step} idx={i} /> 
              ))}
              {dogState.map((dogs, i) => (
                <DogIssues key={parseInt("34569") + i} handleChange={handleChange} handleDogChange={handleDogChange} values={values} dogState={dogState} order={5 + dogState.length + i} step={step} idx={i} /> 
              ))}
              <DogFood handleChange={handleChange} values={values} order={5 + (dogState.length * 2)} step={step} />
              <DogAccessories handleChange={handleChange} values={values} order={6 + (dogState.length * 2)} step={step} />
              <div sx={{position: "fixed", bottom: "0px", left: "0", right: "0", width: "100%", p: 5, backgroundColor: "#fafafa"}}>
                <Flex sx={{justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                  {previousButton()}
                  {nextButton()}       
                </Flex>
              </div>
            </form>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
