/** @jsx jsx */
import { jsx, Flex, Styled, Input} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const ZipCode = ({step, order, handleChange, values}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])
  

  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition 
          classNames="slide" 
          timeout={3000}>
          <div>
        <Flex sx={{justifyCenter: "center", alignItems: "center", flexWrap: "wrap"}}>
            <Flex sx={{width: "100%", justifyContent: "center"}}>
              <Styled.h2>Where do you and your dogs live?</Styled.h2>
            </Flex>
            <div sx={{width: ["100%"], mt: 5}}>
              <label htmlFor="first_name">Zip Code</label>
              <Input
                id="zip_code"
                placeholder="Zip Code"
                name="zip_code"
                type="text"
                value={values.zip_code}
                onChange={handleChange}
              />
            
            </div>
        </Flex>
        </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  ) 
      
}