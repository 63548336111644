/** @jsx jsx */
import { jsx, Flex, Styled, Input, Label} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const Name = ({step, order, handleChange, values}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])
  

  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition 
          classNames="slide" 
          timeout={3000}>
          <div>
        <Flex sx={{justifyCenter: "center", alignItems: "center", flexWrap: "wrap"}}>
            <Flex sx={{width: "100%", justifyContent: "center"}}>
              <Styled.h2>What's your name?</Styled.h2>
            </Flex>
            <div sx={{width: ["100%"], mt: 5}}>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                placeholder="Name"
                name="name"
                type="text"
                value={values.name}
                onChange={handleChange}
              />
            
            </div>
        </Flex>
        </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  ) 
      
}