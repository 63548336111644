/** @jsx jsx */
import { jsx, Input, Styled, Flex, Select} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";


export const DogInfoFields = ({step, order, dogState, handleDogChange, idx}) => {

  const [show, setShow] = useState(false)
  const [breed, setBreed] = useState([])

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])




  useEffect(() => {

    const q = dogState[idx].breed === "" ? "a" : dogState[idx].breed
    fetch(`https://api.thedogapi.com/v1/breeds/search?q=${q}`,{
      method: "GET",
      headers: {
        'x-api-key': 'c3a871b2-58da-4517-9938-5cebdd29094e'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.length !== 0) {
          setBreed([ ...data ])}
        }
      )

  }, [dogState[idx].breed])

  return (    
    <TransitionGroup component={null}>
    {show && (
      <CSSTransition 
        classNames="slide" 
        timeout={3000}>
          <div key={`dog-${idx}`}>
            <Styled.h2 sx={{textAlign: "center"}}>Tell us more about {dogState[idx].name}</Styled.h2>
           
            <Flex sx={{justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
                <Select
                  type="text"
                  name="sex"
                  data-idx={idx}
                  id={`sex-${idx}`}
                  value={dogState[idx].sex}
                  onChange={handleDogChange}
                  placeholder="Sex"
                  sx={{width: "75px"}}
                >
                  <option>He</option>
                  <option>She</option>
                </Select>


                <Styled.p sx={{display: "inline-block", whiteSpace: "nowrap", my: 0,mx: 4}}>is a</Styled.p>

                <Input
                  type="text"
                  name="age"
                  data-idx={idx}
                  id={`age-${idx}`}
                  value={dogState[idx].age}
                  onChange={handleDogChange}
                  placeholder="1"
                  sx={{width: "100px"}}
                />
                
                <Styled.p sx={{display: "inline-block", whiteSpace: "nowrap", my: 0, ml: 4}}>year old</Styled.p>

            </Flex>

            <Flex sx={{justifyContent: "center", alignItems: "center"}}>

                <Input
                  type="text"
                  name="breed"
                  data-idx={idx}
                  id={`breed-${idx}`}
                  value={dogState[idx].breed}
                  onChange={handleDogChange}
                  placeholder="breed"
                  list="dogs"
                />

                <datalist id="dogs">
                  {breed.map(({name},i) => (
                    <option key={i} value={name} />
                  ))}
                </datalist>
      
            </Flex>
            

            <Flex sx={{justifyContent: "center", alignItems: "center"}}>
              
     
          
            </Flex>
            <Flex sx={{justifyContent: "center", alignItems: "center"}}>
              <Styled.p sx={{display: "inline-block", whiteSpace: "nowrap", my: 0, mx: 4}}>and weighs</Styled.p>
              <Input
                type="text"
                name="weight"
                data-idx={idx}
                id={`weight-${idx}`}
                value={dogState[idx].weight}
                onChange={handleDogChange}
                placeholder="25"
                sx={{width: "100px"}}
              /> 
              <Styled.p sx={{whiteSpace: "nowrap", my: 0, ml: 4}}>lbs</Styled.p>
            </Flex>

          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  ) 
}
      