import React from "react"
import {QuizContainer} from "../components/Quiz"
import "../styles/global.css"

const BreedQuiz = () => {

  return (
    <QuizContainer />
  )
}

export default BreedQuiz