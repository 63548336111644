/** @jsx jsx */
import { jsx, Flex, Styled, Input} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {DogNameInput} from "."

export const DogNames = ({step, order, handleDogChange, values, dogState}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])


  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition 
          classNames="slide" 
          timeout={3000}>
          <div>
        <Flex sx={{justifyCenter: "center", alignItems: "center", flexWrap: "wrap"}}>
            <Flex sx={{width: "100%", justifyContent: "center"}}>
              <Styled.h2>{values.dog_quantity > 1 ? "What are their names?" : "What is it's name?"}</Styled.h2>
            </Flex>
          <DogNameInput values={values} dogState={dogState} handleDogChange={handleDogChange} />
        </Flex>
        </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  ) 
      
}